/** @format */

import styled from "styled-components";

export const StyledGallery = styled.div`
  width: 100%;

  .slick-track {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 100%;
  }

  .slick-dots {
    li {
      width: clamp(14px, 1.163vw, 22px);
      height: clamp(14px, 1.163vw, 22px);
      margin-right: clamp(10px, 1.163vw, 20px);

      &:last-child {
        margin-right: 0;
      }

      button {
        width: 100%;
        height: 100%;

        &:before {
          width: 100%;
          height: 100%;
          background-color: #745239;
          border-radius: 50%;
          content: "";
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #745239;
  }

  .slick-prev {
    left: -70px;
  }

  .slick-next {
    right: -70px;
  }

  .slick-next:hover,
  .slick-next:focus,
  .slick-prev:hover,
  .slick-prev:focus {
    color: #745239;
    background: #745239;
  }

  @media only screen and (max-width: 992px) {
    .slick-next,
    .slick-prev {
      display: none !important;
    }
  }
`;

export const StyledHideImage = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 400px;
  margin-bottom: 45px;

  img,
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const StyledOpenWrapper = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  right: 30px;
  top: 30px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: transform 250ms linear;

  &::after {
    content: "";
    width: 100%;
    height: 3px;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: 0;
    transform: rotate(-45deg) translateY(-50%);
  }

  &::before {
    content: "";
    width: 100%;
    height: 3px;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
  }

  &:hover {
    transform: scale(1.05);
  }
`;

export const StyledOpenimageWrapper = styled.div`
  width: auto;
  max-width: 70%;
  height: ${({ references }) => (references ? "auto" : "80%")};
  max-height: ${({ references, maxHeight }) =>
    references || maxHeight ? "80%" : "unset"};
  margin: 0 auto;
  position: relative;

  .gatsby-image-wrapper,
  img {
    width: 100%;
    height: 100%;
    object-fit: ${({ references }) =>
      references ? "scale-down" : "contain"} !important;
  }

  @media only screen and (max-width: 1132px) {
    max-height: 700px;
  }

  @media only screen and (max-width: 900px) {
    max-height: 600px;
  }

  @media only screen and (max-width: 515px) {
    max-height: 400px;
    height: unset;
    max-width: 90%;
  }
`;

export const StyledPagination = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;

  button {
    width: 90px;
    height: 60px;
    border-radius: 15px;
    border: none;
    background-color: rgba(0, 0, 0, 0.9);
    cursor: pointer;

    svg {
      color: #fff;
      height: 30px;
      width: 30px;
    }

    &:last-child {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

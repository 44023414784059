import styled from "styled-components";

export const StyledHomeThirdSection = styled.div`
  width: 100%;
  padding: 69px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 98px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2f2f2d;

  @media only screen and (max-width: 992px) {
    padding: 40px 22px;
  }
`;

export const StyledContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  align-items: center;
  justify-items: center;

  > a {
    position: relative;
    height: 350px;

    img,
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }

    &:hover {
      img {
        transition: transform 250ms linear;
        transform: scale(1.05);
      }
    }

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      width: 100%;
      text-transform: uppercase;
      font-weight: 700;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media only screen and (max-width: 1207px) {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
  }

  @media only screen and (max-width: 668px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

/** @format */

import React from "react";
import { Link } from "gatsby";

import Button from "../../atoms/Button/Button";
import Image from "../../atoms/Image/Image";

import { StyledText } from "../../atoms/StyledText/StyledText";
import {
  StyledHomeThirdSection,
  StyledContent,
} from "./StyledHomeThirdSection";

const HomeThirdSection = ({
  title,
  haleNamioImg,
  haleNamioText,
  haleStaloImg,
  konstruStaloImg,
  haleStaloText,
  konstruStaloText,
  btnText,
}) => {
  return (
    <StyledHomeThirdSection>
      <StyledText
        hasdeclaredfontcolor="#fff"
        hasdeclaredfontsize="clamp(22px,2.092vw,40px)"
        hasdeclaredpadding="0 0 clamp(40px, 3.425vw, 50px) 0"
        hasdeclaredmaxwidth="700px"
        hasdeclaredlineheight="1.35em"
        hasdeclaredtextalign="center"
        hasdeclaredfontweight="700"
      >
        {title}
      </StyledText>
      <StyledContent>
        <Link to="/oferta">
          <StyledText
            hasdeclaredfontcolor="#fff"
            hasdeclaredfontsize="clamp(22px, 1.667vw, 32px)"
            hasdeclaredtextalign="center"
          >
            {haleNamioText}
          </StyledText>
          <Image image={haleNamioImg} />
        </Link>
        <Link to="/oferta">
          <StyledText
            hasdeclaredfontcolor="#fff"
            hasdeclaredfontsize="clamp(22px, 1.667vw, 32px)"
            hasdeclaredtextalign="center"
          >
            {haleStaloText}
          </StyledText>
          <Image image={haleStaloImg} />
        </Link>
        <Link to="/oferta">
          <StyledText
            hasdeclaredfontcolor="#fff"
            hasdeclaredfontsize="clamp(22px, 1.667vw, 32px)"
            hasdeclaredtextalign="center"
          >
            {konstruStaloText}
          </StyledText>
          <Image image={konstruStaloImg} />
        </Link>
      </StyledContent>
      <Button className="home-third-section" text={btnText} where="/oferta" />
    </StyledHomeThirdSection>
  );
};

export default HomeThirdSection;

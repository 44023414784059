/** @format */

import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { StyledReferences, StyledContent } from "./StyledReferences";
import Gallery from "../Gallery/Gallery";
import { StyledText } from "../../atoms/StyledText/StyledText";

const References = () => {
  const {
    allDatoCmsReferencje: { edges },
  } = useStaticQuery(graphql`
    query referencesQuery {
      allDatoCmsReferencje {
        edges {
          node {
            referencja {
              alt
              gatsbyImageData
              title
            }
          }
        }
      }
    }
  `);

  return edges.length ? (
    <StyledReferences id="referencje">
      <StyledText
        hasdeclaredfontsize="clamp(22px, 2.292vw, 44px)"
        hasdeclaredpadding="0 0 clamp(24px, 2.179vw, 42px) 0"
        hasdeclaredfontweight="700"
      >
        Referencje:
      </StyledText>
      <StyledContent>
        <Gallery
          referencesGallery
          images={edges}
          maxHeight={true}
          className="home-page-referecnes"
        />
      </StyledContent>
    </StyledReferences>
  ) : null;
};

export default References;

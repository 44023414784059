/** @format */

import React, { useState, useCallback, useRef } from "react";
import Slider from "react-slick";

import Image from "../../atoms/Image/Image";

import {
  StyledGallery,
  StyledHideImage,
  StyledOpenWrapper,
  StyledCloseButton,
  StyledOpenimageWrapper,
  StyledPagination,
} from "./StyledGallery";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Gallery = ({ images, className, referencesGallery, maxHeight }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [actualImage, setActualImage] = useState(null);
  const slider = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const handleOpenCarousel = useCallback((e) => {
    e.preventDefault();
    setIsOpen(true);
    setActualImage(parseInt(e.target.dataset.index));
  }, []);

  const closeModalImage = (e) => {
    if (e.target.dataset.close === "close") {
      setIsOpen(false);
    }
  };

  const handlePrevImage = useCallback(() => {
    setActualImage(actualImage <= 0 ? images.length - 1 : actualImage - 1);
  }, [actualImage, setActualImage, images]);

  const handleNextImage = useCallback(() => {
    setActualImage(actualImage >= images.length - 1 ? 0 : actualImage + 1);
  }, [actualImage, setActualImage, images]);

  return (
    <StyledGallery className={className}>
      <Slider ref={slider} {...settings}>
        {images?.map((image, index) =>
          image.node ? (
            <StyledHideImage
              key={
                image.node.referencja.alt +
                `${
                  index +
                  image.node.referencja.title +
                  image.node.referencja.alt
                }`
              }
              data-index={index}
              onClick={handleOpenCarousel}
              aria-label="Zdjęcie"
            >
              {image.node.referencja ? (
                <Image
                  image={image.node.referencja}
                  onClick={handleOpenCarousel}
                  dataIndex={index}
                  objectFit="contain"
                />
              ) : null}
            </StyledHideImage>
          ) : image.certyfikat ? (
            <StyledHideImage
              key={
                image.certyfikat.alt +
                `${index + image.certyfikat.title + image.certyfikat.alt}`
              }
              data-index={index}
              onClick={handleOpenCarousel}
              aria-label="Zdjęcie"
            >
              {image.certyfikat ? (
                <Image
                  image={image.certyfikat}
                  onClick={handleOpenCarousel}
                  dataIndex={index}
                  objectFit="contain"
                />
              ) : null}
            </StyledHideImage>
          ) : (
            <StyledHideImage
              key={image.alt + `${index + image.title + image.alt}`}
              data-index={index}
              onClick={handleOpenCarousel}
              aria-label="Zdjęcie"
            >
              {image ? (
                <Image
                  image={image}
                  onClick={handleOpenCarousel}
                  dataIndex={index}
                />
              ) : null}
            </StyledHideImage>
          )
        )}
      </Slider>
      {isOpen ? (
        <StyledOpenWrapper
          onClick={(e) => closeModalImage(e)}
          data-close="close"
        >
          <StyledCloseButton
            onClick={() => setIsOpen(false)}
            aria-label="zamknij"
          />
          <StyledOpenimageWrapper
            maxHeight={maxHeight}
            references={referencesGallery}
          >
            {images[actualImage].node ? (
              <Image image={images[actualImage].node.referencja} />
            ) : images[actualImage].certyfikat ? (
              <Image image={images[actualImage].certyfikat} />
            ) : (
              <Image image={images[actualImage]} />
            )}
          </StyledOpenimageWrapper>
          <StyledPagination>
            <button onClick={handlePrevImage} aria-label="dalej">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.3284 11.0001V13.0001L7.50011 13.0001L10.7426 16.2426L9.32842 17.6568L3.67157 12L9.32842 6.34314L10.7426 7.75735L7.49988 11.0001L20.3284 11.0001Z"
                  fill="currentColor"
                />
              </svg>
            </button>
            <button onClick={handleNextImage} aria-label="wstecz">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.3284 11.0001V13.0001L7.50011 13.0001L10.7426 16.2426L9.32842 17.6568L3.67157 12L9.32842 6.34314L10.7426 7.75735L7.49988 11.0001L20.3284 11.0001Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </StyledPagination>
        </StyledOpenWrapper>
      ) : null}
    </StyledGallery>
  );
};

export default Gallery;

import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { StyledWrapper } from "./Certyfikaty.styles";
import Gallery from "../../molecules/Gallery/Gallery";
import { StyledText } from "../../atoms/StyledText/StyledText";

const Certyfikaty = () => {
  const {
    allDatoCmsCertyfikaty: { nodes },
  } = useStaticQuery(graphql`
    query certyfikaty {
      allDatoCmsCertyfikaty {
        nodes {
          certyfikat {
            alt
            gatsbyImageData
            title
          }
        }
      }
    }
  `);

  return nodes.length ? (
    <StyledWrapper id="certyfikaty">
      <StyledText
        hasdeclaredfontsize="clamp(22px, 2.292vw, 44px)"
        hasdeclaredpadding="0 0 clamp(24px, 2.179vw, 42px) 0"
        hasdeclaredfontweight="700"
      >
        Certyfikaty:
      </StyledText>
      <div>
        <Gallery images={nodes} certsGallery className="home-page-referecnes" />
      </div>
    </StyledWrapper>
  ) : null;
};

export default Certyfikaty;

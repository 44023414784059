/** @format */

import styled from "styled-components";

export const StyledHomeHeroSection = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const SliderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .slick-slider,
  .slick-list,
  .slick-track {
    width: 100%;
    height: 100%;
  }

  .slick-slide {
    > div {
      width: 100%;
      height: 100%;
    }
  }

  img,
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledTextContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  font-size: clamp(18px, 1.667vw, 32px);
  font-weight: 300;
  line-height: 1.35em;
  max-width: 900px;
  padding-left: clamp(22px, 7.031vw, 135px);
  padding-right: 22px;
`;

export const StyledSocialMediaWrapper = styled.a`
  color: #fff;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 80px;
  left: clamp(22px, 7.031vw, 135px);
  font-size: clamp(16px, 1.467vw, 32px);
  font-family: Poppins;
  gap: clamp(12px, 1.563vw, 30px);
  text-decoration: none;
  transition: color 250ms linear;

  &:focus-visible {
    outline-width: 1px;
    outline-style: solid;
    outline-color: #0bc76d;
    outline-offset: 4px;
  }

  &:hover {
    color: #0bc76d;
  }

  svg {
    width: clamp(32px, 2.865vw, 55px);
    height: clamp(32px, 2.865vw, 55px);
  }
`;

/** @format */

import React from "react";
import Button from "../../atoms/Button/Button";
import Image from "../../atoms/Image/Image";
import ParseText from "../../atoms/ParseText/ParseText";

import {
  StyledHomeSecondSection,
  StyledRightWrapper,
  StyledLeftWrapper,
} from "./StyledHomeSecondSection";

const HomeSecondSection = ({ inputText, leftImage, rightText }) => {
  return (
    <StyledHomeSecondSection>
      <StyledLeftWrapper>
        <Image image={leftImage} />
      </StyledLeftWrapper>
      <StyledRightWrapper>
        <ParseText text={rightText} />
        <Button
          text={inputText}
          className="home-second-section"
          where="/kontakt"
        />
      </StyledRightWrapper>
    </StyledHomeSecondSection>
  );
};

export default HomeSecondSection;

import styled from "styled-components";

export const StyledHomeSecondSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: clamp(40px, 5.677vw, 109px);
  padding: 69px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 98px);

  @media only screen and (max-width: 992px) {
    padding: 40px 22px;
  }

  @media only screen and (max-width: 785px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const StyledRightWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font: 500 clamp(16px, 1.102vw, 20px) / 1.35em Poppins;

  @media only screen and (max-width: 785px) {
    width: 100%;
  }
`;

export const StyledLeftWrapper = styled.div`
  width: 40%;
  height: 100%;

  .gatsby-image-wrapper,
  img {
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 785px) {
    width: 100%;
    height: 320px;
  }
`;

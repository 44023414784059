import styled from "styled-components";

export const StyledWrapper = styled.div`
  width: 100%;
  padding: 66px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 98px);
  background-color: #faf7f1;

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }
`;

import styled from "styled-components";

export const StyledReferences = styled.section`
  width: 100%;
  padding: 44px clamp(22px, 9.583vw, 184px) clamp(64px, 5.333vw, 98px);
  margin-top: 40px;

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }
`;

export const StyledContent = styled.div``;

/** @format */

import React from "react";
import { graphql } from "gatsby";

import HomeHeroSection from "../components/organisms/HomeHeroSection/HomeHeroSection";
import Opinions from "../components/organisms/Opinions/Opinions";
import Container from "../components/atoms/Container/Container";
import OurRealizations from "../components/organisms/OurRealizations/OurRealizations";
import FormSection from "../components/organisms/FormSection/FormSection";
import HomeSecondSection from "../components/organisms/HomeSecondSection/HomeSecondSection";
import HomeThirdSection from "../components/organisms/HomeThirdSection/HomeThirdSection";
import References from "../components/molecules/References/References";
import HelmetTemplate from "../components/molecules/HelmetTemplate/HelmetTemplate";
import Certyfikaty from "../components/organisms/Certyfikaty/Certyfikaty";

const IndexPage = ({
  data: {
    datoCmsStronaGWna: {
      facebookLink,
      podTytu,
      slider,
      tytu,
      tekstObokIkonkiFacebooka,
      zdjCiePoLewo,
      tekstPoPrawo,
      tekstDoPrzyciskuPoPrawo,
      tytul,
      zdjecieHalNamiotowych,
      tekstHalNamiotowych,
      zdjecieHalNamiotowychCopy1,
      zdjecieHalNamiotowychCopy2,
      tekstHalNamiotowychCopy1,
      tekstHalNamiotowychCopy2,
      tekstDoPrzycisku,
    },
  },
}) => {
  return (
    <main>
      <HelmetTemplate
        title="Strona główna"
        desc="Kon-stal, budujemy hale stalowe, hale namiotowe, konstrukcje stalowe "
      />
      <Container>
        <HomeHeroSection
          iconText={tekstObokIkonkiFacebooka}
          facebookLink={facebookLink}
          subTitle={podTytu}
          title={tytu}
          slider={slider}
        />
        <HomeSecondSection
          inputText={tekstDoPrzyciskuPoPrawo}
          leftImage={zdjCiePoLewo}
          rightText={tekstPoPrawo}
        />
        <HomeThirdSection
          title={tytul}
          haleNamioImg={zdjecieHalNamiotowych}
          haleNamioText={tekstHalNamiotowych}
          haleStaloImg={zdjecieHalNamiotowychCopy1}
          haleStaloText={tekstHalNamiotowychCopy1}
          konstruStaloImg={zdjecieHalNamiotowychCopy2}
          konstruStaloText={tekstHalNamiotowychCopy2}
          btnText={tekstDoPrzycisku}
        />
        <Certyfikaty />
        <OurRealizations />
        <Opinions />
        <References />
        <FormSection />
      </Container>
    </main>
  );
};

export default IndexPage;

export const query = graphql`
  query homePageQuery {
    datoCmsStronaGWna {
      facebookLink
      podTytu
      slider {
        alt
        title
        gatsbyImageData
      }
      tekstDoPrzycisku
      tekstDoPrzyciskuPoPrawo
      tekstHalNamiotowych
      tekstHalNamiotowychCopy1
      tekstHalNamiotowychCopy2
      tekstObokIkonkiFacebooka
      tekstPoPrawo
      tytu
      tytul
      zdjCiePoLewo {
        alt
        title
        gatsbyImageData
      }
      zdjecieHalNamiotowych {
        alt
        title
        gatsbyImageData
      }
      zdjecieHalNamiotowychCopy1 {
        alt
        title
        gatsbyImageData
      }
      zdjecieHalNamiotowychCopy2 {
        alt
        title
        gatsbyImageData
      }
    }
  }
`;
